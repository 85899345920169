import React, {useEffect} from 'react';

import * as L from "leaflet";


export default function ZoomControl({onZoomIn, onZoomOut}) {


    const onZoomInClick = (e) => {
        e.preventDefault();
        onZoomIn();
    }


    const onZoomOutClick = (e) => {
        e.preventDefault();
        onZoomOut();
    }


    return (
        <div className="btn-group-vertical">
            <button onClick={onZoomInClick} className={'btn btn-onmap'}><i className={'ti ti-plus fs-5'}></i></button>
            <button onClick={onZoomOutClick} className={'btn btn-onmap'}><i className={'ti ti-minus fs-5'}></i></button>
        </div>
    );
}