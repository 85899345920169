import React, {useEffect} from "react";
import CardLoading from "./CardLoading";

export default function SimpleModal({
    visible = false,
    loading = false,
    onClose = null,
    modalContentClassName = '',

    headerTitle = "Sample Modal",

    footer = null,

    children
}) {
    const [show, setShow] = React.useState(visible);
    useEffect(() => {
        setShow(visible);
    }, [visible]);


    const onCloseClick = (e) => {
        e.preventDefault();

        setShow(false);
        if (onClose) {
            onClose();
        }
    }


    return (
        <div className={'modal bg-dark bg-opacity-50 fade'+(!!show ? ' show' : '')+(loading ? ' loading' : '')} tabIndex="-1" aria-labelledby="vertical-center-modal" aria-hidden={!show} style={{display: (!!show ? 'block' : '')}}>
            <div className="modal-dialog modal-dialog-centered modal-md">
                <div className={'modal-content '+(modalContentClassName ? modalContentClassName : '')}>
                    {headerTitle ? (
                        <div className="modal-header px-3 d-flex align-items-center">
                            <h4 className="modal-title fs-4 fw-semibold">{headerTitle}</h4>
                            <button type="button" className="btn btn-light-danger btn-circle btn-sm d-inline-flex align-items-center justify-content-center" onClick={onCloseClick}>
                                <i className="fs-3 ti ti-x text-danger"></i>
                            </button>
                        </div>
                    ) : (
                        <button type="button" className="btn btn-circle btn-sm d-inline-flex align-items-center justify-content-center position-absolute top-0 end-0 m-3 bg-light-danger z-index-5"
                                onClick={onCloseClick}>
                            <i className="fs-3 ti ti-x text-danger"></i>
                        </button>
                    )}

                    <div className="modal-body p-3 pt-0">
                        {children}
                    </div>

                    {footer && (
                        <div className="modal-footer px-3">
                            {footer}
                        </div>
                    )}

                    <CardLoading loading={loading} />
                </div>
            </div>
        </div>
    )
}