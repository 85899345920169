import React from "react";
import CardLoading from "./CardLoading";

function SimpleCardContainer(props) {
    return (
        <div className={'card w-100 position-relative overflow-hidden '+(props.loading ? ' loading' : '')}>
            <div className="px-4 py-3 border-bottom d-flex align-items-center justify-content-between">
                <h5 className="align-items-center card-title d-flex fw-semibold gap-2 lh-sm mb-0">{props.title}</h5>

                {props.titleRight && (
                    <div className="d-flex align-items-center">
                        {props.titleRight}
                    </div>
                )}
            </div>
            <div className="card-body p-4">
                {props.children}
            </div>

            <CardLoading loading={props.loading} />
        </div>
    )
}

export default SimpleCardContainer;