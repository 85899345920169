import {LexicalComposer} from '@lexical/react/LexicalComposer';
import ToolbarPlugin from './wysiwyg/ToolbarPlugin.tsx';
import {ContentEditable} from '@lexical/react/LexicalContentEditable';
import {HistoryPlugin} from '@lexical/react/LexicalHistoryPlugin';
import {OnChangePlugin} from '@lexical/react/LexicalOnChangePlugin';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import {RichTextPlugin} from "@lexical/react/LexicalRichTextPlugin";
import {useEffect, useRef} from "react";


export default function ShapePopup({initialJSON, onSave}) {
    const editorRef = useRef(null);

    const theme = {
        text: {
            strikethrough: 'text-decoration-line-through',
            underline: 'text-decoration-underline',
            underlineStrikethrough: 'text-decoration-line-through text-decoration-underline',
        }
    }


    const onChange = (editorState) => {
        if (editorState && Object.keys(editorState).length > 0) {
            const json = editorState.toJSON();
            json.isEmpty = function() {
                return !!this.root;
            };
            onSave(JSON.stringify(json));
        }
    }


    const onError = (error) => {
        console.error(error);
    }


    const initialConfig = {
        namespace: 'MyEditor',
        theme,
        onError,
    };
    if (initialJSON) {
        initialConfig.editorState = initialJSON;
    }


    return (
        <LexicalComposer initialConfig={initialConfig}>
            <div className="editor-container">
                <ToolbarPlugin/>
                <div className="editor-inner" ref={editorRef}>
                    <RichTextPlugin
                        contentEditable={<ContentEditable />}
                        theme={theme}
                        placeholder={<div className="fs-2">Enter some text...</div>}
                        ErrorBoundary={LexicalErrorBoundary}
                    />
                    <OnChangePlugin onChange={onChange} ignoreHistoryMergeTagChange={true} ignoreSelectionChange={true} />
                    <HistoryPlugin/>
                </div>
            </div>
        </LexicalComposer>
    );
}