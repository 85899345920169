import React from "react";

export default function FormTextField({label, name, errors, onChange, value, type = 'text', placeholder = '', groupClassName = '', className = '', required = false, disabled = false, readOnly = false, autoComplete = 'off', autoFocus = false, hint = ''}) {

    const onFieldChange = (e) => {
        onChange(e.target.value);
    }

    return (
        <div className={'form-group '+groupClassName}>
            <label className="form-label fw-semibold mb-2" htmlFor={'cf-'+name}>{label}</label>

            <input id={'cf-'+name} className={'form-control '+className} value={value ? value : ''} onChange={onFieldChange} type={type} placeholder={placeholder} required={required} disabled={disabled} readOnly={readOnly} autoComplete={autoComplete} autoFocus={autoFocus}/>

            <div className="d-flex justify-content-between mt-1">
                {errors && errors[name] && <div className="form-text text-danger">{errors[name].join(' ')}</div>}
                <div className={'flex-grow-1 text-end fs-2'}>{hint}</div>
            </div>
        </div>
    )
}