import React, {useEffect} from 'react';

import SimpleCardContainer from "../ui/SimpleCardContainer";
import {useApi} from "../../providers/ApiProvider";
import UsersModel from "../../models/UsersModel";
import {useBreadcrumbs} from "../../providers/BreadcrumbsProvider";
import {useAuth} from "../../providers/AuthProvider";
import {useParams} from "react-router-dom";
import ProjectsModel from "../../models/ProjectsModel";
import ButtonWithLoading from "../layout/ui/ButtonWithLoading";
import FormTextArea from "../layout/ui/FormTextArea";

export default function AdminProjectEdit(props) {
    const {projectId} = useParams();
    const apiContext = useApi();
    const projectsModel = new ProjectsModel(apiContext.api);
    const usersModel = new UsersModel(apiContext.api);

    const [project, setProject] = React.useState({});
    const [projectData, setProjectData] = React.useState('');
    const [users, setUsers] = React.useState([]);
    const [title, setTitle] = React.useState('');
    const [description, setDescription] = React.useState('');
    const [loading, setLoading] = React.useState('general');


    useEffect(() => {
        projectsModel.view(projectId).then((response) => {
            if (response !== false) {
                document.title = response.title;

                setProject(response);
                setProjectData(JSON.stringify(response.data, null, 2));
            }
        });

        usersModel.list(['id', 'name', 'email']).then((response) => {
            if (response !== false) {
                setUsers(response);
            }
        });
    }, []);


    useEffect(() => {
        if (title !== project.title) {
            setTitle(project.title+'');
        }
        if (description !== project.description) {
            setDescription(project.description+'');
        }
    }, [project]);


    const fieldChanged = (field, value) => {
        setProject({
            ...project,
            [field]: value
        });
    }


    const onSubmit = (e) => {
        e.preventDefault();

        let parsedData = {};
        try {
            parsedData = JSON.parse(projectData);
        } catch (e) {
            alert('Invalid JSON format for data');
            return;
        }

        let data = {
            title: project.title,
            description: project.description,
            user_id: project.user_id,
            params: project.params,
            data: parsedData
        };

        projectsModel.update(projectId, data).then((response) => {
            if (response !== false) {
                window.location.href = '/projects/' + projectId;
            }
        });
    }


    return (
        <SimpleCardContainer title={'Edit project information'}>
            <form className={'py-2'}>
                <div className="row">
                    <div className="col-xl-4 col-md-6">
                        <FormTextArea
                            label={'Data'}
                            name={'data'}
                            value={projectData}
                            onChange={(value) => {
                                setProjectData(value);
                            }}
                            groupClassName={'mb-3'}
                            hint={'JSON format'}
                            isCodeMirror={true}
                        />

                        <div className={'form-group mb-3'}>
                            <ButtonWithLoading
                                className={'btn btn-primary'}
                                label={'Save changes'}
                                loading={loading === 'submit'}
                                onClick={onSubmit}
                            />
                        </div>
                    </div>
                    <div className="col-xl-4 col-md-6">
                        <div className={'form-group mb-3'}>
                            <label className="form-label fw-semibold mb-2">{'Project title'}</label>

                            <input id={'cd-name'} className={'form-control'} value={title} onChange={(e) => {
                                fieldChanged('title', e.target.value)
                            }}/>
                        </div>

                        <div className={'form-group mb-3'}>
                            <label className="form-label fw-semibold mb-2">{'Description'}</label>

                            <input id={'cd-description'} className={'form-control'} value={description} onChange={(e) => {
                                fieldChanged('description', e.target.value)
                            }}/>
                        </div>

                        <div className={'form-group mb-3'}>
                            <label className="form-label fw-semibold mb-2">{'User'}</label>

                            <select id={'cd-button'} className={'form-control form-select'} value={project.user_id} onChange={(e) => {
                                fieldChanged('user_id', e.target.value)
                            }}>
                                {users.map((user, key) => {
                                    return <option value={user.id} key={key}>{user.name} / {user.email}</option>
                                })}
                            </select>
                        </div>

                        <FormTextArea
                            label={'Parameters'}
                            name={'params'}
                            value={project.params}
                            onChange={(value) => {
                                fieldChanged('params', value)
                            }}
                            groupClassName={'mb-3'}
                            hint={'JSON format'}
                            isCodeMirror={true}
                        />
                    </div>
                </div>
            </form>
        </SimpleCardContainer>
    );
}
