import React from "react";

export default function ButtonWithLoading({
                                              className = '', // button classes in addition to default
                                              spinnerClassName = '', // spinner classes in addition to default
                                              iconClassName = '', // icon classes in addition to default
                                              label = '',
                                              onClick,
                                              loading = false,
                                              noOriginalClassName = false, // hide default button classes
                                              noOriginalSpinnerClassName = false, // hide default spinner
                                              noOriginalIconClassName = false, // hide default icon
                                          }) {
    let spinnerJoint;
    if (!loading) {
        spinnerJoint = 'd-none';
    } else {
        if (!noOriginalSpinnerClassName) {
            spinnerJoint = 'spinner-border spinner-border-sm me-2';
        }

        if (spinnerClassName) {
            spinnerJoint += ' ' + spinnerClassName;
        }
    }


    return (
        <button className={className + ((noOriginalClassName) ? '' : ' btn d-flex align-items-center')} onClick={onClick}>
            <span className={spinnerJoint} role="status" aria-hidden={!loading}></span>
            <i className={iconClassName + ((!noOriginalIconClassName) ? ' ti ti-message-plus fs-4 me-2' : '') + ((loading) ? ' d-none' : '')}></i>
            {label}
        </button>
    );
}