import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function TableEditableRow(props) {


    const onEdit = (e) => {
        e.preventDefault();

        props.onEdit(props.data.pk);
    }


    const onDelete = (e) => {
        e.preventDefault();

        if (window.confirm('Are you sure you want to delete this item?')) {
            props.onDelete(props.data.pk);
        }
    }


    if (props.loading === true) {
        return (
            <tr>
                {
                    [...Array(props.columns+1)].map((item, index) => {
                        return (
                            <td className="align-middle text-nowrap" key={index}>
                                <div className="progress mt-4">
                                    <div className="progress-bar progress-bar-striped bg-dark progress-bar-animated" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style={{width: '100%'}}></div>
                                </div>
                            </td>
                        );
                    })
                }
            </tr>
        );
    } else if (props.loading === undefined && props.data.data) {
        return (
            <tr>
                {props.data.data.map((item, index) => {
                    return (
                        <td key={index}>{item}</td>
                    )
                })}
                <td>
                    <div className="dropdown dropstart">
                        <a href="#" className="text-muted" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                            <i className="ti ti-dots fs-5"></i>
                        </a>
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <li>
                                <a className="dropdown-item d-flex align-items-center gap-3" href="#" onClick={onEdit}><i className="fs-4 ti ti-edit"></i>Edit</a>
                            </li>
                            <li>
                                <a className="dropdown-item d-flex align-items-center gap-3" href="#" onClick={onDelete}><i className="fs-4 ti ti-trash"></i>Delete</a>
                            </li>
                        </ul>
                    </div>
                </td>
            </tr>
        );
    }

    return (<></>);
}

export default TableEditableRow;