import React from 'react';

import {useBreadcrumbs} from "../../providers/BreadcrumbsProvider";

import {Link} from "react-router-dom";


const Breadcrumbs = (props) => {
    const breadcrumbsContext = useBreadcrumbs();

    if (breadcrumbsContext.title) {
        return (
            <div className="card bg-light-info shadow-none position-relative overflow-hidden">
                <div className="card-body px-4 py-3">
                    <div className="align-items-center">
                        <h4 className="fw-semibold mb-8">{breadcrumbsContext.title}</h4>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to="/" className={'text-muted d-block'} style={{paddingTop: '1px'}}><i className={'ti ti-home fs-4'}></i></Link>
                                </li>
                                {breadcrumbsContext.breadcrumbs && breadcrumbsContext.breadcrumbs.map((breadcrumb, index) => {
                                    if (breadcrumbsContext.breadcrumbs.length > index+1) {
                                        return (
                                            <li className="breadcrumb-item" key={index}>
                                                <Link to={breadcrumb.url} className={'text-muted'}>{breadcrumb.label}</Link>
                                            </li>
                                        );
                                    } else {
                                        return (
                                            <li className="breadcrumb-item" aria-current="page" key={index}>
                                                {breadcrumb.label}
                                            </li>
                                        );
                                    }
                                })}
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        );
    } else {
        return '';
    }
}

export default Breadcrumbs;
