import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import imgLogo from '../../assets/img/logo.png';

import imgProfileTmp from 'modernize-essentials/dist/images/profile/user-1.jpg';

import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.min.js';
import SimpleBar from "simplebar-react";
import {useAuth} from "../../providers/AuthProvider";
import {useApi} from "../../providers/ApiProvider";


//using Tabler Icons from https://tabler-icons.io/

const Nav = (props) => {
    const authContext = useAuth();
    const userData = authContext.user;

    const apiContext = useApi();

    let imgProfile = imgProfileTmp;
    if (userData.avatar) {
        imgProfile = userData.avatar;
    }


    const onNavbarToggle = (e) => {
        e.preventDefault();

        const htmlClass = 'navbar-vertical-collapsed';
        const html = document.querySelector('html');

        if (html.classList.contains(htmlClass)) {
            html.classList.remove(htmlClass);
        } else {
            html.classList.add(htmlClass);
        }

        const navbarVertical = document.querySelector('#navbarVerticalCollapse');
        const navbarVerticalCollapse = new bootstrap.Collapse(navbarVertical, {
            toggle: false
        });
        navbarVerticalCollapse.toggle();
    }


    const onLogoutClick = (e) => {
        e.preventDefault();

        window.location.href = '/auth/logout';
    }


    let menuDefault = {
        'projects': {
            'label': 'Projects',
            'items': {
                'drawing-area': {
                    'icon': 'plus',
                    'title': 'New Project',
                    'url': '/'
                },
                /*'mailing-files': {
                    'icon': 'mail-opened',
                    'title': 'Mailing Files',
                    'url': '/p/mailing-files',
                    'count': null
                },*/
            }
        },
        'files': {
            'label': 'Files',
            'items': {
                'files': {
                    'icon': 'folders',
                    'title': 'My Files',
                    'url': '/files'
                },
                'all-projects': {
                    'icon': 'star',
                    'title': 'Mailing Projects',
                    'url': '/projects'
                },
                'shared-projects': {
                    'icon': 'share-3',
                    'title': 'Shared Projects',
                    'url': '/projects/shared'
                },
            }
        },
    }
    if (userData.type === 'admin') {
        menuDefault['admin'] = {
            'label': 'Admin',
            'items': {
                'users': {
                    'icon': 'users',
                    'title': 'Users',
                    'url': '/users'
                },
            }
        }
    }
    const [menu, setMenu] = React.useState(menuDefault);
    const isElementActive = (key, section) => {
        let simpleKeys = {
            'projects': ['drawing-area', 'mailing-files'],
            'files': ['files', 'all-projects', 'shared-projects'],
        };
        if (userData.type === 'admin') {
            simpleKeys['admin'] = ['users'];
        }

        if (simpleKeys[section].indexOf(key) !== -1) {
            if (window.location.pathname.indexOf(menu[section]['items'][key].url) !== -1) {
                return true;
            }
        }

        return false;
    }


    /*React.useEffect(() => {
        accountModel.getGeneralInfo().then((response) => {
            if (response !== false) {
                const newMenu = {...menu};
                newMenu['businesses']['items']['projects-list']['count'] = response.businessesCount;
                setMenu(newMenu);
            }
        });
    }, []);*/


    return (
        <aside className={'left-sidebar d-block d-xl-none'}>
            <div>
                <nav className="sidebar-nav scroll-sidebar container-fluid mw-100">
                    <ul id="sidebarnav">
                        {Object.keys(menu).map((section, sIndex) => {
                            return (
                                <React.Fragment key={sIndex}>
                                    <li className="nav-small-cap">
                                        <i className="ti ti-dots nav-small-cap-icon fs-4"></i>
                                        <span className="hide-menu">{menu[section]['label']}</span>
                                    </li>
                                    {Object.keys(menu[section]['items']).map((key, index) => {
                                        const element = menu[section]['items'][key];
                                        return (
                                            <li className={`sidebar-item ${isElementActive(key, section) ? 'selected' : ''}`} key={sIndex + '-' + index}>
                                                <a className={`sidebar-link ${isElementActive(key, section) ? 'active' : ''}`} href={element.url}
                                                   aria-expanded="false">
                                                    <span>
                                                        <i className={`ti ti-${element.icon}`}></i>
                                                    </span>
                                                    <span className="hide-menu">{element.title}</span>
                                                    {typeof element.count === 'number' && (
                                                        <span className="hide-menu" style={{marginLeft: 'auto'}}><span
                                                            className="badge rounded-circle bg-primary d-flex align-items-center justify-content-center round-20 p-0 fs-2">{element.count}</span></span>
                                                    )}
                                                </a>
                                            </li>
                                        );
                                    })}
                                </React.Fragment>
                            );
                        })}
                    </ul>
                </nav>
            </div>
            <div className="fixed-profile p-2 sidebar-ad mt-3 mx-9 d-flex d-xl-none">
                <div className="hstack gap-3 justify-content-between">
                    <div className="john-img">
                        <img src={imgProfile} className="rounded-circle" width="40" height="40" alt=""/>
                    </div>
                    <div className="john-title">
                        <h6 className="mb-0 fs-4 fw-semibold">{userData.name}</h6>
                        <span className="fs-2 text-dark">{userData.type}</span>
                    </div>
                    <button className="border-0 bg-transparent text-primary ms-3" tabIndex="0" onClick={onLogoutClick}>
                        <i className="ti ti-logout fs-6"></i>
                    </button>
                </div>
            </div>
        </aside>
    );
}

export default Nav;
