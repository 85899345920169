import React from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';

import SimpleLayout from "../layout/SimpleLayout";
import {Navigate, Outlet} from "react-router-dom";
import {useAuth, userStructure} from "../../providers/AuthProvider";
import {useApi} from "../../providers/ApiProvider";
import GoogleAuthProcessing from "./GoogleAuthProcessing";
import Error404 from "../errors/Error404";
import {useTranslation} from "react-i18next";
import {useTranslation as useMyTranslation} from "../../providers/TranslationProvider";
import UsersModel from "../../models/UsersModel";


const Auth = (props) => {
    const { t} = useTranslation();
    const authContext = useAuth();
    const apiContext = useApi();
    const userModel = new UsersModel(apiContext.api);

    const [isAuthenticated, setIsAuthenticated] = React.useState('none');

    const onSuccessfulAuth = (response) => {
        let user = userModel.processAuthResponse(response);

        authContext.login(user);

        setIsAuthenticated('authenticated');
    }


    const onGoogleAuth = (googleAuthCode) => {
        const credentials = {
            googleAuthCode: googleAuthCode
        }

        setIsAuthenticated('authenticating');

        apiContext.api.authLoginWithGoogle(credentials).then((response) => {
            if (response === false) {
                alert(t('Wrong credentials.'));
            } else {
                onSuccessfulAuth(response);
            }
        });
    }


    return (
        <SimpleLayout>
            <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
                {
                    isAuthenticated === 'authenticated' && (<Navigate to={'/'} replace={true} />) ||
                    isAuthenticated === 'authenticating' && (<GoogleAuthProcessing />) ||
                    isAuthenticated === 'none' && (<Outlet
                        context={{
                            onSuccessfulAuth: onSuccessfulAuth,
                            onGoogleAuth: onGoogleAuth
                        }}
                    />) ||
                    (<Error404 />)
                }
            </GoogleOAuthProvider>
        </SimpleLayout>
    );
}

export default Auth;
