import React, {useEffect} from 'react';

import {useApi} from "../../providers/ApiProvider";

import ProfilePhotoCard from "./ProfilePhotoCard";
import ChangePasswordCard from "./ChangePasswordCard";
import PersonalInfoCard from "./PersonalInfoCard";
import UsersModel from "../../models/UsersModel";
import {useBreadcrumbs} from "../../providers/BreadcrumbsProvider";
import {useAuth} from "../../providers/AuthProvider";

function AccountInfoTab(props) {
    const {user} = useAuth();
    const apiContext = useApi();
    const usersModel = new UsersModel(apiContext.api);
    const breadcrumbs = useBreadcrumbs();

    const [personalInfo, setPersonalInfo] = React.useState({
        name: '',
        email: '',
        avatar: '',
        settings: {}
    });
    const resetPersonalInfo = () => {
        usersModel.view(user.id).then((response) => {
            if (response !== false) {
                setPersonalInfo({
                    name: response.name,
                    email: response.email,
                    avatar: response.avatar,
                    settings: response.settings
                });
            }
        });
    }


    useEffect(() => {
        resetPersonalInfo();
    }, []);


    return (
        <div className="row">
            <div className="col-lg-4 d-flex align-items-stretch">
                <ProfilePhotoCard
                    personalInfo={personalInfo}
                    resetPersonalInfo={resetPersonalInfo}
                />
            </div>
            <div className="col-lg-4 d-flex align-items-stretch">
                <PersonalInfoCard
                    usersModel={usersModel}
                    name={personalInfo.name}
                    email={personalInfo.email}
                    settings={personalInfo.settings}
                    resetPersonalInfo={resetPersonalInfo}
                />
            </div>
            <div className="col-lg-4 d-flex align-items-stretch">
                <ChangePasswordCard
                    usersModel={usersModel}
                />
            </div>
        </div>
    );
}

export default AccountInfoTab;
