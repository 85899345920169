/**
 * Coordinates model
 *
 * @param {ApiHelper} api
 */
export default class CoordinatesModel {
    constructor(api) {
        this.api = api;
    }


    async list(data, errorHandler) {
        let url = '/coordinates?';
        let params = [];
        Object.keys(data).forEach((key) => {
            params.push(key+'='+data[key]);
        });
        url += params.join('&');

        const response = await this.api.getRequest(url);

        return this.processResponse(response, errorHandler);
    }


    async listPolygon(data, errorHandler) {
        let url = '/coordinates-polygon';

        const response = await this.api.postRequest(url, data);

        return this.processResponse(response, errorHandler);
    }


    async coordinatesInfo(lat, lng, errorHandler) {
        let url = '/coordinates-info?lat='+lat+'&lng='+lng;

        const response = await this.api.getRequest(url);

        return this.processResponse(response, errorHandler);
    }


    processResponse(response, errorHandler) {
        if (response.status.toString().startsWith('20') && response.data && response.data.success === true && response.data.data) {
            return response.data.data;
        } else {
            if (errorHandler) {
                errorHandler(response.data);
            }
        }

        return false;
    }
}