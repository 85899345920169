import MapHelper from "../../../helpers/MapHelper";
import * as L from "leaflet";


export default class UnionShape {
    shapes = [];
    union = null;
    bounds = null;


    constructor(shapes) {
        this._dryShapes(shapes);

        if (shapes) {
            this.union = MapHelper.getUnionShape(shapes);
            this.bounds = L.geoJSON(this.union).getBounds();
        }
    }


    refresh(shapes) {
        this._dryShapes(shapes);

        if (shapes) {
            this.union = MapHelper.getUnionShape(shapes);
            this.bounds = L.geoJSON(this.union).getBounds();
        } else {
            this.union = null;
            this.bounds = null;
        }
    }


    _dryShapes(shapes) {
        let dryShapes = {};
        if (shapes && Object.values(shapes).length) {
            Object.values(shapes).forEach((shape) => {
                if (!shape) {return;}
                dryShapes[shape.id] = {
                    id: shape.id,
                    type: shape.type,
                    geoJSON: shape.geoJSON,
                    layerData: shape.layerData,
                    bounds: shape.bounds,
                };
            });
        }

        this.shapes = dryShapes;
    }
}