import React, {useEffect, useState} from "react";
import {useApi} from "../../../../providers/ApiProvider";
import {useAuth} from "../../../../providers/AuthProvider";
import UsersModel from "../../../../models/UsersModel";
import {useNotifications} from "../../../../providers/NotificationsProvider";

export default function UnitsToggle({}) {
    const apiContext = useApi();
    const usersModel = new UsersModel(apiContext.api);
    const {user} = useAuth();
    const notifications = useNotifications();

    let baseUnits = 'disable';
    if (user.settings && user.settings.units) {
        baseUnits = user.settings.units;
    }
    const [units, setUnits] = useState(baseUnits);


    useEffect(() => {
        if (!user.id) {
            return;
        }

        usersModel.view(user.id).then((response) => {
            if (response && response.settings && response.settings.units) {
                setUnits(response.settings.units);

                let newUser = JSON.parse(JSON.stringify(user));
                if (!newUser.settings || Object.keys(newUser.settings).length === 0) {
                    newUser.settings = {};
                }
                newUser.settings.units = response.settings.units;
                localStorage.setItem('user', JSON.stringify(newUser));
            }
        });
    }, []);


    const onClick = (newUnits) => {
        let newSettings = JSON.parse(JSON.stringify(user.settings));
        if (Object.keys(newSettings).length === 0) {
            newSettings = {};
        }
        newSettings['units'] = newUnits;

        usersModel.update(user.id, {settings: newSettings}).then((response) => {
            if (response) {
                setUnits(newUnits);
                let newUser = JSON.parse(JSON.stringify(user));
                if (!newUser.settings) {
                    newUser.settings = {};
                }
                newUser.settings.units = newUnits;
                localStorage.setItem('user', JSON.stringify(newUser));
                window.location.reload();
            } else {
                notifications.notify('Failed to update units', 'error');
            }
        });
    }


    return (
        <div className="btn-group">
            <button className={'btn btn-onmap fs-2' + (units === 'disable' ? ' bg-light-primary fw-bold text-primary' : '')} onClick={() => {
                onClick('disable')
            }}>off
            </button>
            <button className={'btn btn-onmap fs-2' + (units === 'imperial' ? ' bg-light-primary fw-bold text-primary' : '')} onClick={() => {
                onClick('imperial')
            }}>mi
            </button>
            <button className={'btn btn-onmap fs-2' + (units === 'metric' ? ' bg-light-primary fw-bold text-primary' : '')} onClick={() => {
                onClick('metric')
            }}>km
            </button>
        </div>
    );
}