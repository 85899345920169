import React, {useEffect} from 'react';

import imgLogo from '../../assets/img/logo.png';
import HeaderProfile from "./ui/HeaderProfile";
import HeaderNotifications from "./ui/HeaderNotifications";
import {Link} from "react-router-dom";
import {useAuth} from "../../providers/AuthProvider";
import {useApi} from "../../providers/ApiProvider";
import ProjectsModel from "../../models/ProjectsModel";
import {useNotifications} from "../../providers/NotificationsProvider";

export default function Header(props) {
    const authContext = useAuth();
    const userData = authContext.user;

    const apiContext = useApi();
    const projectsModel = new ProjectsModel(apiContext.api);

    const [loading, setLoading] = React.useState('');

    useEffect(() => {
        window.addEventListener('scroll', function () {
            if (document.querySelector('.app-header')) {
                if (window.scrollY > 60) {
                    document.querySelector('.app-header').classList.add('fixed-header');
                } else {
                    document.querySelector('.app-header').classList.remove('fixed-header');
                }
            }
        });

        window.addEventListener('resize', onWindowResize);

        onWindowResize();
    }, []);


    const onWindowResize = () => {
        if (document.querySelector('#main-wrapper')) {
            if (window.innerWidth < 1300) {
                document.querySelector('#main-wrapper').setAttribute('data-sidebartype', 'mini-sidebar');
                document.querySelector('#main-wrapper').classList.add('mini-sidebar');
            } else {
                document.querySelector('#main-wrapper').setAttribute('data-sidebartype', 'full');
                document.querySelector('#main-wrapper').classList.remove('mini-sidebar');
            }
        }
    }


    const createProject = (e) => {
        e.preventDefault();
        setLoading('createProject');

        if (userData && userData.id) {
            projectsModel.create().then((response) => {
                if (response && response.id) {
                    setTimeout(() => {
                        window.location.href = '/p/'+response.id;
                    }, 1000);
                }
            }).finally(() => {
                setLoading('');
            });
        } else {
            window.location.href = '/auth/login';
        }
    }


    return (<>
        <header className="app-header px-0">
            <nav className="navbar navbar-expand-xl navbar-light container-fluid px-0 mw-100">
                <ul className="navbar-nav ms-3 ps-3">
                    <li className="nav-item d-block d-xl-none ms-n4">
                        <a className="nav-link sidebartoggler px-4" href="#" onClick={props.onSidebarToggle}>
                            <i className="ti ti-menu-2"></i>
                        </a>
                    </li>
                    <li className="nav-item d-none d-xl-block">
                        <Link to={'/'} className={'text-nowrap'}>
                            <img src={imgLogo} height="50" alt=""/>
                        </Link>
                    </li>
                </ul>
                <div className="d-block d-xl-none ps-3">
                    <Link to={'/'} className="text-nowrap">
                        <img src={imgLogo} height="50" alt=""/>
                    </Link>
                </div>

                <ul className="navbar-nav d-none d-xl-flex w-100 ms-5">
                    <li className="nav-item me-2">
                        <a className="nav-link fs-4 fw-semibold " href="/p/create" onClick={createProject}>
                            {loading === 'createProject' ? (
                            <span className="spinner-border spinner-border-sm me-2"></span>
                            ) : (
                            <i className="ti ti-plus fs-5 me-2"></i>
                            )}
                            New Project
                        </a>
                    </li>
                    <li className="nav-item me-2 d-none">
                        <a className="nav-link fs-4 fw-semibold" href="/"><i className="ti ti-mail-opened fs-5 me-2"></i>Mailing Files</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link fs-4 fw-semibold" href="/files"><i className="ti ti-folders fs-5 me-2"></i>My Files</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link fs-4 fw-semibold" href="/p"><i className="ti ti-star fs-5 me-2"></i>Mailing Projects</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link fs-4 fw-semibold" href="/p/shared"><i className="ti ti-share-3 fs-5 me-2"></i>Shared Projects</a>
                    </li>
                    {userData.type === 'admin' && (<>
                        <li className="nav-item">
                            <a className="nav-link fs-4 fw-semibold" href="/users"><i className="ti ti-users fs-5 me-2"></i>Users</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link fs-4 fw-semibold" href="/projects"><i className="ti ti-stars fs-5 me-2"></i>All Projects</a>
                        </li>
                    </>
                    )}
                </ul>

                <ul className="navbar-nav flex-row align-items-center justify-content-end">
                    <li className="nav-item dropdown d-none">
                    <HeaderNotifications/>
                    </li>
                    <li className="nav-item dropdown d-none d-xl-block pe-3">
                        {userData && userData.id && (<HeaderProfile/>)}
                    </li>
                </ul>
            </nav>
        </header>
    </>);
}