import React from 'react';

import imgLogo from '../../assets/img/logo.png';

function SimpleLayout(props) {

    return (
        <>
            <div className="position-relative overflow-hidden radial-gradient min-vh-100 d-flex align-items-center justify-content-center">
                <div className="d-flex align-items-center justify-content-center w-100">
                    <div className="row justify-content-center w-100">
                        <div className="col-md-8 col-lg-6 col-xxl-3">
                            <div className="card mb-0">
                                <div className="card-body">
                                    <a href="/" className="text-nowrap logo-img text-center d-block mb-5 w-100">
                                        <img src={imgLogo} height="60" alt="logo" />
                                    </a>
                                    {props.children}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SimpleLayout;
