import * as React from "react";
import axios from "axios";
import {jwtDecode} from "jwt-decode";

class ApiHelper {
    constructor(apiUrl) {
        this.apiUrlBase = apiUrl;

        this.apiUrls = {
            auth: {
                login: '/auth/login',
                loginWithGoogle: '/auth/login-with-google',
                register: '/auth/register',
                forgot: '/auth/forgot-password',
                forgotConfirm: '/auth/forgot-password/confirm',
                logout: '/auth/logout',
                refreshToken: '/auth/refresh',
                selfUpdateToken: '/auth/self-update',
            },
            users: {
                list: '/users/list',
                view: '/users/:id',
            },
        };
    }


    async authLogin(credentials) {
        let url = this.apiUrls.auth.login;
        return this.postRequest(url, credentials);
    }


    async authLoginWithGoogle(credentials) {
        let url = this.apiUrls.auth.loginWithGoogle;
        return this.postRequest(url, credentials);
    }


    async authForgotPassword(credentials) {
        let url = this.apiUrls.auth.forgot;
        return this.postRequest(url, credentials);
    }


    async authForgotPasswordConfirm(credentials) {
        let url = this.apiUrls.auth.forgotConfirm;
        return this.postRequest(url, credentials);
    }


    async authRegister(credentials) {
        let url = this.apiUrls.auth.register;
        return this.postRequest(url, credentials);
    }


    /**
     * Make GET request to API endpoint
     * @param {string} url a relative API endpoint URL
     * @returns {Object|null} JSON-decoded response
     */
    async getRequest(url) {
        let accessToken = localStorage.getItem('access_token'),
            fullUrl = (url.indexOf('https://') !== -1)?(url):(this.apiUrlBase + url),
            requestParams = {
                url: fullUrl,
                headers: {
                    'Accept': 'application/json, text/plain, */*'
                }
            };

        if (accessToken) {
            if (!requestParams.headers) {
                requestParams.headers = {};
            }
            requestParams.headers['Authorization'] = 'Bearer ' + accessToken;
        }

        return await this._request(requestParams);
    }


    /**
     * Make POST request to API endpoint
     * @param {string} url a relative API endpoint URL
     * @param {Object|null} data data to send
     * @returns {Promise|null} JSON-decoded response
     */
    async postRequestRaw(url, data = null) {
        let accessToken = localStorage.getItem('access_token'),
            fullUrl = (url.indexOf('https://') !== -1)?(url):(this.apiUrlBase + url),
            requestParams = {
                method: 'POST',
                url: fullUrl,
                headers: {
                    'Accept': 'application/json, text/plain',
                    'Content-Type': 'multipart/form-data'
                },
                data: data
            };

        if (accessToken) {
            if (!requestParams.headers) {
                requestParams.headers = {};
            }
            requestParams.headers['Authorization'] = 'Bearer ' + accessToken;
        }

        return await this._request(requestParams);
    }


    /**
     * Make POST request to API endpoint
     * @param {string} url a relative API endpoint URL
     * @param {Object|null} data data to send
     * @returns {Promise|null} JSON-decoded response
     */
    async postRequest(url, data = null) {
        let accessToken = localStorage.getItem('access_token'),
            fullUrl = this.apiUrlBase + url,
            requestParams = {
                method: 'POST',
                url: fullUrl,
                headers: {
                    'Accept': 'application/json, text/plain',
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify(data),
            };

        if (accessToken) {
            if (!requestParams.headers) {
                requestParams.headers = {};
            }
            requestParams.headers['Authorization'] = 'Bearer ' + accessToken;
        }

        return await this._request(requestParams);
    }


    /**
     * Make POST request to send file to API endpoint
     * @param {string} url a relative API endpoint URL
     * @param {Object|null} data data to send
     * @returns {Promise|null} JSON-decoded response
     */
    async postRequestFile(url, data = null) {
        let accessToken = localStorage.getItem('access_token'),
            fullUrl = this.apiUrlBase + url,
            requestParams = {
                method: 'POST',
                url: fullUrl,
                headers: {
                    'Accept': 'application/json, text/plain',
                    'Content-Type': 'multipart/form-data'
                },
                data: data
            };

        if (accessToken) {
            if (!requestParams.headers) {
                requestParams.headers = {};
            }
            requestParams.headers['Authorization'] = 'Bearer ' + accessToken;
        }

        return await this._request(requestParams);
    }


    /**
     * Make PUT request to API endpoint
     * @param {string} url a relative API endpoint URL
     * @param {Object|null} data data to send
     * @returns {Promise|null} JSON-decoded response
     */
    async putRequest(url, data = null) {
        let accessToken = localStorage.getItem('access_token'),
            fullUrl = this.apiUrlBase + url,
            requestParams = {
                method: 'PUT',
                url: fullUrl,
                headers: {
                    'Accept': 'application/json, text/plain',
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify(data),
            };

        if (accessToken) {
            if (!requestParams.headers) {
                requestParams.headers = {};
            }
            requestParams.headers['Authorization'] = 'Bearer ' + accessToken;
        }

        return await this._request(requestParams);
    }


    /**
     * Make DELETE request to API endpoint
     * @param {string} url a relative API endpoint URL
     * @returns {Promise|null} JSON-decoded response
     */
    async deleteRequest(url) {
        let accessToken = localStorage.getItem('access_token'),
            fullUrl = this.apiUrlBase + url,
            requestParams = {
                method: 'DELETE',
                url: fullUrl,
                headers: {
                    'Accept': 'application/json, text/plain',
                    'Content-Type': 'application/json'
                }
            };

        if (accessToken) {
            if (!requestParams.headers) {
                requestParams.headers = {};
            }
            requestParams.headers['Authorization'] = 'Bearer ' + accessToken;
        }

        return await this._request(requestParams);
    }


    /**
     * Perform a request and handle errors, if any
     * @param {Object} requestParams
     * @returns {Promise<*>}
     * @private
     */
    async _request(requestParams) {
        let results = null;

        requestParams = await this._refreshTokenIfNeeded(requestParams);

        try {
            let response = await axios.request(requestParams);

            if (response.status === 401) {
                if (process.env.REACT_APP_DEBUG_MODE === 'true') {
                    console.log('%cRequest error:', 'color: white; background-color: red');
                    console.log('requestParams', requestParams);
                    console.log('response', response);
                } else {
                    window.location = '/auth/logout';
                }

                results = response;
            } else {
                results = response;
            }
        } catch (e) {
            if (process.env.REACT_APP_DEBUG_MODE === 'true') {
                console.log('%cRequest error:', 'color: white; background-color: red');
                console.log('requestParams', requestParams);
                console.log('error', e);
            } else if (e.response.status === 401) {
                window.location = '/auth/logout';
            }

            results = e.response;
        }

        return results;
    }


    /**
     * Refresh access token if needed
     */
    async _refreshTokenIfNeeded(requestParams) {
        if (requestParams.headers['Authorization']) {
            const accessTokenExpiresAt = localStorage.getItem('access_token_expires_at');
            const refreshToken = localStorage.getItem('refresh_token');
            const refreshTokenExpiresAt = localStorage.getItem('refresh_token_expires_at');

            if (
                accessTokenExpiresAt &&
                accessTokenExpiresAt < Math.floor(Date.now() / 1000 + 60) //add a gap of 1 minute until token expires
            ) {
                // update access token with refresh token, if possible
                if (
                    refreshToken &&
                    refreshTokenExpiresAt > Math.floor(Date.now() / 1000 + 60) //add a gap of 1 minute until token expires
                ) {
                    const newTokens = await this._refreshToken(refreshToken);
                    if (newTokens.data && newTokens.data.access_token) {
                        const accessTokenJWT = jwtDecode(newTokens.data.access_token);
                        const refreshTokenJWT = jwtDecode(newTokens.data.refresh_token);

                        localStorage.setItem('access_token', newTokens.data.access_token);
                        localStorage.setItem('access_token_expires_at', accessTokenJWT.exp);
                        localStorage.setItem('refresh_token', newTokens.data.refresh_token);
                        localStorage.setItem('refresh_token_expires_at', refreshTokenJWT.exp);

                        requestParams.headers['Authorization'] = 'Bearer ' + newTokens.data.access_token;
                    }
                } else if (accessTokenExpiresAt >= Math.floor(Date.now() / 1000)) {
                // try to update access token moving "sliding window"

                    const currentAccessToken = localStorage.getItem('access_token');
                    const newAccessToken = await this._selfUpdateToken(currentAccessToken);
                    if (newAccessToken.data && newAccessToken.data.access_token) {
                        const accessTokenJWT = jwtDecode(newAccessToken.data.access_token);

                        localStorage.setItem('access_token', newAccessToken.data.access_token);
                        localStorage.setItem('access_token_expires_at', accessTokenJWT.exp);

                        requestParams.headers['Authorization'] = 'Bearer ' + newAccessToken.data.access_token;
                    }
                }
            }
        }

        return requestParams;
    }


    /**
     * Refresh access token
     */
    async _refreshToken(refreshToken) {
        const requestParams = {
            method: 'POST',
            url: this.apiUrlBase + this.apiUrls.auth.refreshToken,
            headers: {
                'Accept': 'application/json, text/plain',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + refreshToken
            }
        };

        return axios.request(requestParams);
    }


    /**
     * Refresh access token
     */
    async _selfUpdateToken(accessToken) {
        const requestParams = {
            method: 'POST',
            url: this.apiUrlBase + this.apiUrls.auth.selfUpdateToken,
            headers: {
                'Accept': 'application/json, text/plain',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        };

        return axios.request(requestParams);
    }
}


const ApiContext = React.createContext(null);
function ApiProvider({children}) {
    const apiHelper = new ApiHelper(process.env.REACT_APP_API_URL);
    let [api, setApi] = React.useState(apiHelper);



    let value = {api};

    return <ApiContext.Provider value={value}>{children}</ApiContext.Provider>;
}
function useApi() {return React.useContext(ApiContext);}

export {ApiProvider, useApi};