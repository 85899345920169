import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {useAuth} from "../providers/AuthProvider";

export default function InactivityHandler() {
    const auth = useAuth();
    const navigate = useNavigate();
    const INACTIVITY_TIMEOUT = 10 * 60 * 1000; // 10 minutes


    const logout = () => {
        auth.logout();

        localStorage.setItem('lastActivity', Date.now().toString());
        navigate('/auth/login');
    };


    const handleActivity = () => {
        localStorage.setItem('lastActivity', Date.now().toString());
    };


    useEffect(() => {
        handleActivity();

        window.addEventListener('mousemove', handleActivity);
        window.addEventListener('keypress', handleActivity);

        const checkInactivity = () => {
            const lastActivity = parseInt(localStorage.getItem('lastActivity'), 10);
            const currentTime = Date.now();
            if (currentTime - lastActivity > INACTIVITY_TIMEOUT) {
                logout();
            }
        };

        const interval = setInterval(checkInactivity, 1000);

        return () => {
            window.removeEventListener('mousemove', handleActivity);
            window.removeEventListener('keypress', handleActivity);
            clearInterval(interval);
        };
    }, []);


    return null;
};