import React, { useState, useEffect, useRef } from 'react';
import * as L from "leaflet";
import AddressAutocomplete from "./AddressAutocomplete";
import {AsyncTypeahead} from "react-bootstrap-typeahead";

export default function MapSearchControlGetAddress({address, onChange}) {
    const [currentAddress, setCurrentAddress] = useState(address);
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState([]);


    useEffect(() => {
        setCurrentAddress(address);
    }, [address]);


    const onSearch = (query) => {
        setIsLoading(true);
        fetch(`https://api.getAddress.io/autocomplete/${query}?all=true&api-key=${process.env.REACT_APP_GETADDRESS_TOKEN}`)
            .then(resp => resp.json())
            .then(json => {
                setIsLoading(false);

                setOptions(json.suggestions);
            });
    };


    const processOnChange = (selected) => {
        if (selected.length > 0) {
            setCurrentAddress(selected[0].address);

            const url = `https://api.getAddress.io${selected[0].url}?api-key=${process.env.REACT_APP_GETADDRESS_TOKEN}`;
            fetch(url)
                .then(resp => resp.json())
                .then(json => {
                    onChange(json.latitude, json.longitude, json);
                });
        }
    }


    return (
        <div className={'flex-grow-1'}>
            <div className="position-absolute end-0 top-50 translate-middle-y pe-4 me-1"><i className="ti ti-search fs-4"></i></div>

            <AsyncTypeahead
                id={'top-search'}
                isLoading={isLoading}
                labelKey={option => `${option.address}`}
                onSearch={onSearch}
                onChange={processOnChange}
                options={options}
                filterBy={() => true}
                className={'form-control input-onmap'}
                placeholder={currentAddress ? currentAddress : 'Search...'}
            />


            {/*<AddressAutocomplete
                value={currentAddress ? currentAddress : ''}
                onChange={processAutocompleteResponse}
                types={['(regions)']}
                componentRestrictions={{ country: 'uk' }}
                className={'form-control input-onmap'}
            />*/}
        </div>
    );
}