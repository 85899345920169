import React, {useEffect} from 'react';
import FormTextField from "../layout/ui/FormTextField";
import FormTextArea from "../layout/ui/FormTextArea";

export default function FilesForm({formData, onSubmit, errors, loading, isNewRecord, className = ''}) {
    const [data, setData] = React.useState({});


    useEffect(() => {
        /*if (typeof formData === 'string') {
            try {
                formData = JSON.parse(formData);
            } catch (e) {
                formData = {};
            }
        }*/

        setData(formData);
    }, [formData]);


    const updateField = (field, value) => {
        let newData = {...data};
        if (field === 'data' && typeof value === 'object') {
            value = JSON.stringify(value, null, 2);
        } else {
            newData[field] = value;
        }

        setData(newData);
    }


    const onFileChange = (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('file', e.target.files[0]);

        //get file contents
        let reader = new FileReader();
        reader.onload = (e) => {
            const fileContents = e.target.result;

            // check if fileContents is a valid JSON
            try {
                JSON.parse(fileContents, null, 2);
                updateField('data', fileContents);
            } catch (e) {
                let geoJSON = saveCSVFileData(fileContents);
                updateField('data', JSON.stringify(geoJSON, null, 2));
            }
        };
        reader.readAsText(e.target.files[0]);
    }


    const saveCSVFileData = (fileContents) => {
        let csv = fileContents.split('\n');
        let csvData = [];
        csv.map((row, index) => {
            if (index > 0) {
                let columns = row.split(';');
                if (columns.length !== 2) {
                    columns = row.split(',');
                }

                if (columns.length === 2) {
                    columns = columns.map((column) => {
                        return parseFloat(column.replace(',', '.'));
                    });
                    csvData.push(columns);
                }
            }
        });

        const lastRow = csvData[csvData.length - 1];
        const firstRow = csvData[0];
        if (JSON.stringify(lastRow) !== JSON.stringify(firstRow)) {
            csvData.push(firstRow);
        }

        return arrayToPolygonGeoJSON(csvData);
    }
    const arrayToPolygonGeoJSON = (array) => {
        return {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    array
                ]
            }
        };
    }


    const triggerFileChange = (e) => {
        e.preventDefault();

        document.querySelector('#change-file').click();
    }


    const onSubmitClick = (e) => {
        e.preventDefault();

        onSubmit(data);
    }


    return (
        <form className={'py-2'}>
            <div className={'row'}>
                <div className={'col-md-8'}>
                    <FormTextField
                        label={'Name'}
                        name={'name'}
                        errors={errors}
                        onChange={(value) => {
                            updateField('name', value)
                        }}
                        value={data.name}
                        required={true}
                    />

                    <div className={'row'}>
                        <div className={'col-md-9'}>
                            <FormTextArea
                                label={'File'}
                                name={'data'}
                                errors={errors}
                                onChange={(value) => {
                                    updateField('data', value)
                                }}
                                value={((typeof data.data === 'object') ? JSON.stringify(data.data, null, 2) : data.data)}
                                hint={'Should be JSON formatted.'}
                                className={'form-control'}
                                isCodeMirror={true}
                                groupClassName={'mt-3'}
                            />
                        </div>
                        <div className={'col-md-3'}>
                            <input type="file" accept=".json,.geojson" className="d-none" id="change-file" onChange={onFileChange}/>

                            <div className="d-flex align-items-center justify-content-center mt-5 gap-3">
                                <button className="btn btn-light-primary text-primary" onClick={triggerFileChange}>{'Upload a data file...'}</button>
                            </div>
                        </div>
                    </div>

                    <div className={'form-group mt-3'}>
                        <button className="btn btn-primary mt-3" onClick={onSubmitClick}>{'Save changes'}</button>
                    </div>
                </div>
            </div>
        </form>
    );
}
