import React from 'react';

import {Outlet} from "react-router-dom";
import {useAuth} from "../../providers/AuthProvider";
import MainLayout from "../layout/MainLayout";

export default function AdminProjects() {
    const {user} = useAuth();

    // check if user role is admin
    if (!user || user.type !== 'admin') {
        return null;
    }

    return (
        <MainLayout>
            <Outlet />
        </MainLayout>
    );
}