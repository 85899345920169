import React, {useEffect, useState} from 'react';

import {Link} from "react-router-dom";
import {useApi} from "../../providers/ApiProvider";
import {useTranslation} from "react-i18next";
import {useTranslation as useMyTranslation} from "../../providers/TranslationProvider";

function ForgotPasswordConfirm(props) {
    const {language} = useMyTranslation();
    const { t} = useTranslation();
    const apiContext = useApi();

    const [token, setToken] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [error, setError] = useState('');
    const [tokenValid, setTokenValid] = useState(false);
    const [success, setSuccess] = useState(false);


    const handleSubmit = (e) => {
        e.preventDefault();

        if (token === '') {
            setError(t('Your password reset link is broken.'));
            return;
        }

        if (password === '' || passwordConfirm === '') {
            setError(t('Please, enter your new password.'));
            return;
        }

        if (password !== passwordConfirm) {
            setError(t('Passwords do not match.'));
            return;
        }

        const credentials = {
            token: token,
            password: password
        }

        apiContext.api.authForgotPasswordConfirm(credentials).then((response) => {
            if (response === false) {
                setError(t('Something went wrong.'));
            } else {
                setSuccess(true);

                setTimeout(() => {
                    window.location = '/auth/login';
                }, 5000);
            }
        });
    }


    useEffect(() => {
        document.title = t('Update your password')+' | Geolist';

        const urlParams = new URLSearchParams(window.location.search);
        const urlToken = urlParams.get('token');
        if (urlToken !== null && urlToken !== '') {
            setToken(urlToken);
            setTokenValid(true);
        } else {
            setError(t('Your password reset link is broken.'));
        }
    }, [language]);


    return (
        <>
            <h4 className="mb-0">{t('Update your password')}</h4>

            {success && (
                <div className="alert alert-primary mt-4" role="alert">{t('Password updated successfully! We\'ll redirect you to the login page shortly.')}</div>
            ) || !success && (<>
                <small>{t('Please, type in your new password to change it.')}</small>

                {error !== '' && <div className="alert alert-danger mt-4" role="alert">{error}</div>}

                {tokenValid && (
                <form className="mb-3 mt-4">
                    <div className="mb-4">
                        <label htmlFor="split-login-password" className="form-label">{t('Password')}</label>
                        <input type="password" className="form-control" id="split-login-password" value={password} onChange={(e) => setPassword(e.target.value)} />
                    </div>

                    <div className="mb-4">
                        <label htmlFor="split-login-password-confirm" className="form-label">{t('Password Confirm')}</label>
                        <input type="password" className="form-control" id="split-login-password-confirm" value={passwordConfirm} onChange={(e) => setPasswordConfirm(e.target.value)} />
                    </div>

                    <button className="btn btn-primary d-block w-100 mt-3" type="submit" name="submit" onClick={handleSubmit}>{t('Change Password')}</button>
                </form>
                )}
            </>)}

            <Link className="fs--1 text-600" to="/auth/login"><span className="d-inline-block me-1">&larr;</span> {t('Go back to login page')}</Link>
        </>
    );
}

export default ForgotPasswordConfirm;
