import React, {useEffect, useState} from 'react';
import {useAuth} from "../../providers/AuthProvider";
import {Navigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useTranslation as useMyTranslation} from "../../providers/TranslationProvider";

const GoogleAuthProcessing = (props) => {
    const {language} = useMyTranslation();
    const { t} = useTranslation();


    useEffect(() => {
        document.title = t('Authenticating')+' | Geolist';
    }, [language]);


    return (
        <>
            <div className="text-center">
                <h4 className="mb-0">{t('Authenticating')}...</h4>
            </div>
        </>
    );
}

export default GoogleAuthProcessing;
