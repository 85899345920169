import React, {useEffect} from 'react';

import {useBreadcrumbs} from "../../providers/BreadcrumbsProvider";
import SimpleCardContainer from "../ui/SimpleCardContainer";
import {useNotifications} from "../../providers/NotificationsProvider";
import {useApi} from "../../providers/ApiProvider";
import ProjectsModel from "../../models/ProjectsModel";
import FilesSearchForm from "./FilesSearchForm";
import {Link} from "react-router-dom";
import SimpleModal from "../ui/SimpleModal";
import ProjectSharesModel from "../../models/ProjectSharesModel";
import ShapesModel from "../../models/ShapesModel";

export default function FilesList(props) {
    const MODULE_BASE_URL = '/files';
    const breadcrumbs = useBreadcrumbs();
    const notifications = useNotifications();

    const apiContext = useApi();
    const shapesModel = new ShapesModel(apiContext.api);

    const [showDeleteConfirmModal, setShowDeleteConfirmModal] = React.useState(false);
    const [data, setData] = React.useState([]);
    const [filter, setFilter] = React.useState({
        searchText: null,
        sorting: {field: 'date', direction: 'desc'}
    });
    const [loading, setLoading] = React.useState(true);


    const loadData = () => {
        setLoading(true);

        let params = {
            filter: filter.searchText ? filter.searchText : '',
            sort: filter.sorting.field,
            direction: filter.sorting.direction
        };

        shapesModel.list(params, uploadErrorHandler).then((response) => {
            if (response !== false && response.data) {
                setData(response.data);
            }
        }).finally(() => {
            setLoading(false);
        });
    }


    const uploadErrorHandler = (response) => {
        if (response.data && response.data[0].error) {
            notifications.notify('Loading error', 'error');
        }
    }


    useEffect(() => {
        breadcrumbs.update({
            title: ''
        });

        document.title = 'My Files | Geolist';

        let newFilter = {...filter};
        let urlParams = new URLSearchParams(window.location.search);
        let search = urlParams.get('filter');
        if (search) {
            newFilter.searchText = search;
        }

        let sort = urlParams.get('sort');
        let direction = urlParams.get('direction');
        if (sort && direction) {
            newFilter.sorting = {field: sort, direction: direction};
        }

        if (!search && !sort && !direction) {
            loadData();
        } else {
            setFilter(newFilter);
        }
    }, []);


    useEffect(() => {
        loadData();
        updateUrl();
    }, [filter]);


    const updateUrl = () => {
        let url = '/files';
        let query = {};
        if (filter.searchText) {
            query.filter = filter.searchText;
        }
        if (filter.sorting.field && filter.sorting.direction) {
            query.sort = filter.sorting.field;
            query.direction = filter.sorting.direction;
        }
        if (Object.keys(query).length) {
            url += '?' + new URLSearchParams(query).toString();
        }
        window.history.pushState({}, '', url);
    }


    const onSearch = (searchText) => {
        let newFilter = {...filter};
        newFilter.searchText = searchText;
        setFilter(newFilter);
    }


    const onSort = (e) => {
        let newFilter = {...filter};
        let sortField = e.target.getAttribute('data-sort');
        let sortDirection = 'asc';

        if (e.target.classList.contains('asc')) {
            e.target.classList.remove('asc');
            e.target.classList.add('desc');

            if (e.target.querySelector('i.ti')) {
                e.target.querySelector('i.ti').classList.remove('ti-sort-ascending');
                e.target.querySelector('i.ti').classList.add('ti-sort-descending');
            }

            sortDirection = 'desc';
        } else {
            e.target.classList.remove('desc');
            e.target.classList.add('asc');

            if (e.target.querySelector('i.ti')) {
                e.target.querySelector('i.ti').classList.remove('ti-sort-descending');
                e.target.querySelector('i.ti').classList.add('ti-sort-ascending');
            }
        }

        newFilter.sorting = {field: sortField, direction: sortDirection};
        setFilter(newFilter);
    }


    const onCopyClick = (projectId) => {
        setLoading(true);

        shapesModel.copy(projectId, uploadErrorHandler).then((response) => {
            if (response !== false) {
                notifications.notify('File copied', 'success');
                loadData();
            }
        }).finally(() => {
            setLoading(false);
        });
    }


    const onDeleteClick = (projectId) => {
        setLoading(true);

        shapesModel.delete(projectId, uploadErrorHandler).then((response) => {
            if (response !== false) {
                notifications.notify('File deleted', 'success');
                setShowDeleteConfirmModal(false);
                loadData();
            }
        }).finally(() => {
            setLoading(false);
        });
    }


    return (
        <>
            <SimpleCardContainer
                title={'My Files'}
                loading={loading}
            >
                <div className="d-flex justify-content-between pt-2 pb-4">
                    <Link to={MODULE_BASE_URL+'/create'} className={'btn btn-light-success text-success'}>{'Add a new file'}</Link>
                    <FilesSearchForm onSearch={onSearch}/>
                </div>

                {(data && data.length || loading) ? (
                    <div className="table-responsive rounded-2">
                        <table className="table border customize-table mb-0 align-middle">
                            <thead className="text-dark fs-4">
                            <tr>
                                <th>
                                    <h6 className={'fs-4 cursor-pointer fw-semibold mb-0 table-heading-sortable ' + ((filter.sorting.field === 'name') ? (((filter.sorting.direction === 'desc') ? (' desc') : (' asc'))) : (''))}
                                        data-sort="title" onClick={onSort}>
                                        File Name
                                        {(filter.sorting.field === 'title') && (filter.sorting.direction === 'asc') && (
                                            <i className={'ti ti-sort-ascending fs-5'}></i>
                                        )}
                                        {(filter.sorting.field === 'title') && (filter.sorting.direction === 'desc') && (
                                            <i className={'ti ti-sort-descending fs-5'}></i>
                                        )}
                                    </h6>
                                </th>
                                <th style={{width: '200px'}}>
                                    <h6 className={'fs-4 cursor-pointer fw-semibold mb-0 table-heading-sortable ' + ((filter.sorting.field === 'updated_at') ? (((filter.sorting.direction === 'desc') ? (' desc') : (' asc'))) : (''))}
                                        data-sort="last_updated" onClick={onSort}>
                                        Last Updated
                                        {(filter.sorting.field === 'last_updated') && (filter.sorting.direction === 'asc') && (
                                            <i className={'ti ti-sort-ascending fs-5'}></i>
                                        )}
                                        {(filter.sorting.field === 'last_updated') && (filter.sorting.direction === 'desc') && (
                                            <i className={'ti ti-sort-descending fs-5'}></i>
                                        )}
                                    </h6>
                                </th>
                                <th style={{width: '160px'}}><h6 className="fs-4 fw-semibold mb-0"></h6></th>
                            </tr>
                            </thead>
                            <tbody>
                            {data && data.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td>
                                            <div className="d-flex gap-1 flex-column">
                                                <Link to={MODULE_BASE_URL+'/' + item.id}>{item.name}</Link>
                                            </div>
                                        </td>
                                        <td>{(new Date(item.updated_at)).toLocaleString()}</td>
                                        <td>
                                            <div className="d-flex flex-row gap-3">
                                                <Link className="btn btn-circle btn-light-primary text-primary" to={MODULE_BASE_URL+'/' + item.id}>
                                                    <i className="ti ti-pencil fs-4"></i>
                                                </Link>

                                                <button className="btn btn-circle btn-light-danger text-danger" onClick={() => {
                                                    setShowDeleteConfirmModal(item.id)
                                                }}>
                                                    <i className="ti ti-trash fs-4"></i>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <div className="text-center fs-2 mt-4">
                        No files found.
                    </div>
                )}
            </SimpleCardContainer>

            <SimpleModal
                visible={showDeleteConfirmModal}
                onClose={() => {
                    setShowDeleteConfirmModal(false)
                }}
                modalContentClassName={'modal-filled bg-light-danger'}
                headerTitle={null}
            >
                <div className="text-center pt-3">
                    <i className="ti ti-hexagon-letter-x fs-7 text-danger"></i>
                    <h4 className="mt-2 text-danger">Confirm Delete</h4>
                    <p className="mt-3">Please confirm that you want to delete this file.</p>

                    <button
                        type="button"
                        className="btn btn-danger my-2"
                        onClick={() => {
                            onDeleteClick(showDeleteConfirmModal)
                        }}
                    >
                        Confirm Delete
                    </button>
                </div>
            </SimpleModal>
        </>
    );
}