
import {ChromePicker} from 'react-color';


export default function ShapeColorPicker({visible = false, color, onChange, onChangeComplete}) {


    return (
        <div className={'end-100 me-3 mt-n3 position-absolute top-0'+(visible ? '' : ' d-none')}>
            <ChromePicker
                color={color}
                onChange={onChange}
                onChangeComplete={onChangeComplete}
            />
        </div>
    );
}