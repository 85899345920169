import React, {useEffect, useRef, useState} from 'react';
import L from 'leaflet';
import {useMap} from "../../../../providers/MapProvider";
import ShapeStyling from "../../shapes/ShapeStyling";
import {useAuth} from "../../../../providers/AuthProvider";

const MapWithMeasurement = () => {
    const {user} = useAuth();
    let distInMiles = 1609.34;
    let distInKm = 1000.0;
    if (user.settings && user.settings.units === 'imperial') {
        distInKm = distInMiles;
    }
    const [measurementCoef, setMeasurementСoef] = useState(distInKm);

    const markers = useRef([]);
    const polyline = useRef([]);
    const [distance, setDistance] = useState(0);

    const mapContext = useMap();
    const [map, setMap] = React.useState(null);

    const markerIcon = L.divIcon({ className: 'leaflet-marker-icon marker-icon leaflet-zoom-animated leaflet-interactive' });

    useEffect(() => {
        if (mapContext.isInitialized) {
            setMap(mapContext.map);
        }
    }, [mapContext.isInitialized]);


    const onMarkerAdd = (e) => {
        if (!e.latlng) return;

        const marker = L.marker(e.latlng, { draggable: true, icon: markerIcon }).addTo(map);
        L.Util.stamp(marker);
        marker.on('dblclick', (e) => removeMarker(e, marker));
        marker.on('click', (e) => { L.DomEvent.stopPropagation(e) });
        marker.on('drag', (e) => handleDrag(e));
        markers.current = [...markers.current, marker];

        if (markers.current.length > 0) {
            updatePolyline();
        }
    };


    const handleDrag = () => {
        updatePolyline();
    };


    const removeMarker = (e, markerToRemove) => {
        L.DomEvent.stopPropagation(e);
        map.removeLayer(markerToRemove);
        markers.current = markers.current.filter(marker => L.Util.stamp(marker) !== L.Util.stamp(markerToRemove));
        updatePolyline();
    };


    const updatePolyline = () => {
        const latlngs = markers.current.map(marker => marker.getLatLng());
        if (latlngs.length > 1) {
            polyline.current.setLatLngs(latlngs);
        } else if (polyline.current) {
            polyline.current.setLatLngs([]);
        };
        calculateTotalDistance(latlngs);
    };


    const calculateTotalDistance = (latlngs) => {
        let dist = 0;
        for (let i = 1; i < latlngs.length; i++) {
            dist += latlngs[i].distanceTo(latlngs[i - 1]);
        }
        setDistance(dist / measurementCoef);
    };


    useEffect(() => {
        if (!map) return;

        polyline.current = L.polyline([], ShapeStyling.DRAWING_OPTIONS_POSITIVE.pathOptions).addTo(map);

        map.on('click', onMarkerAdd);

        return () => {
            map.off('click', onMarkerAdd);
            markers.current.forEach((marker) => {
                marker.off('dblclick');
                marker.off('drag');
                marker.remove(map);
            });
            if (polyline) { polyline.current.setLatLngs([]); }
        };
    }, [map]);


    // const removeAllMarkers = () => {
    //     markers.current.forEach((marker) => map.removeLayer(marker));
    //     markers.current = [];
    //     updatePolyline();
    // };

    return (
        <div>
            <p className='m-0'>
                Distance: {distance ? `${distance.toFixed(2)} ${measurementCoef === distInKm ? 'km' : 'mi'}` : ''}
            </p>
        </div>
    );};

export default MapWithMeasurement;
