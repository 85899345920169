import BaseCrudModel from "./BaseCrudModel";

/**
 * Projects model
 *
 * @param {ApiHelper} api
 */
export default class ShapesModel extends BaseCrudModel {
    baseUrl = '/shapes';

    constructor(api) {
        super(api);
    }


    async list(params = {}, errorHandler) {
        let url = this.baseUrl;
        if (params) {
            url += '?' + new URLSearchParams(params).toString();
        }

        const response = await this.api.getRequest(url);

        return this.processResponse(response, errorHandler);
    }


    async update(pk, data, errorHandler) {
        const url = this.baseUrl+'/' + pk;

        const response = await this.api.putRequest(url, data);

        return this.processResponse(response, errorHandler);
    }
}