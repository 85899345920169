import { createRoot } from 'react-dom/client';
import * as L from "leaflet";
import 'leaflet.markercluster';
import 'leaflet.markercluster.freezable';
import {useMap} from "../../../providers/MapProvider";
import {useEffect, useRef, useState} from "react";

import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import MarkerPopup from "./MarkerPopup";
import ShapeStyling from "../shapes/ShapeStyling";
import chroma from "chroma-js";
import {useApi} from "../../../providers/ApiProvider";


class ClusterStyling {
    static getPositiveClusterIcon(cluster, shapeColor) {
        if (!shapeColor) {shapeColor = ShapeStyling.COLOR_POSITIVE;}
        const contrastColor = chroma.contrast(shapeColor, 'white') > 2 ? 'white' : 'black';

        let style = '--cluster-color: '+shapeColor+'; --cluster-text-color: '+contrastColor+';';
        return L.divIcon({ html: '<div class="cluster-styled" style="'+style+'">' + cluster.getChildCount() + '</div>', className: 'cluster-styled-cover'});
    }

    static getNegativeClusterIcon(cluster, shapeColor) {
        if (!shapeColor) {shapeColor = ShapeStyling.COLOR_NEGATIVE;}
        const contrastColor = chroma.contrast(shapeColor, 'white') > 2 ? 'white' : 'black';

        let style = 'opacity: 0.5; --cluster-color: '+shapeColor+'; --cluster-text-color: '+contrastColor+';';
        return L.divIcon({ html: '<div class="cluster-styled" style="'+style+'">' + cluster.getChildCount() + '</div>', className: 'cluster-styled-cover'});
    }
}


export default function Locations({paneName = '', locationsByShape, shapeColor = null, clusterified = false, sortOrder = 0}) {
    const mapContext = useMap();
    const apiContext = useApi();

    const markerClusterPositive = useRef(L.markerClusterGroup({clusterPane: paneName, iconCreateFunction: (cluster) => ClusterStyling.getPositiveClusterIcon(cluster, shapeColor)}));
    const markersPositive = useRef([]);
    const markerClusterNegative = useRef(L.markerClusterGroup({clusterPane: paneName, iconCreateFunction: (cluster) => ClusterStyling.getNegativeClusterIcon(cluster, shapeColor)}));
    const markersNegative = useRef([]);
    const pinCircle = '<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="20" cy="20" r="20" style="" /></svg>';
    const pinTriangle = '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve"><path style="" d="M507.521,427.394L282.655,52.617c-12.074-20.122-41.237-20.122-53.311,0L4.479,427.394c-12.433,20.72,2.493,47.08,26.655,47.08h449.732C505.029,474.474,519.955,448.114,507.521,427.394z"/></svg>';

    useEffect(() => {
        if (!mapContext.isInitialized) {return;}
        if (markersPositive.current.length) {
            markersPositive.current.forEach((marker) => {
                marker.setZIndexOffset(sortOrder);
            });
        }
        if (markersNegative.current.length) {
            markersNegative.current.forEach((marker) => {
                marker.setZIndexOffset(sortOrder);
            });
        }
    }, [sortOrder]);


    const renderPopup = (marker, locations, dataVersion) => {
        let popup = marker.getPopup();
        if (!popup) {
            marker.bindPopup('');
            popup = marker.getPopup();
        }

        const container = document.createElement('div');
        container.classList.add('leaflet-loc-popup-content-wrapper');
        const root = createRoot(container);
        root.render(<MarkerPopup marker={marker} dataVersion={dataVersion} markerLocations={locations} apiContext={apiContext} />);

        popup.setContent(container);
        popup.openPopup();
    }
    const renderPopupRef = useRef(renderPopup);
    useEffect(() => {
        renderPopupRef.current = renderPopup;
    }, [renderPopup]);


    useEffect(() => {
        if (clusterified) {
            markerClusterPositive.current.enableClustering();
            markerClusterNegative.current.enableClustering();
        } else {
            markerClusterPositive.current.disableClustering();
            markerClusterNegative.current.disableClustering();
        }
    }, [clusterified]);


    const createMarkerIcon = (union, filter) => {
        let markerColor = shapeColor;
        if (!shapeColor) {markerColor = union ? ShapeStyling.COLOR_POSITIVE : ShapeStyling.COLOR_NEGATIVE;}
        let html = '';
        let iconSize = [10, 10],
            iconAnchor = [5, 5],
            popupAnchor = [0, -15];
        if (filter === 2) {
            //markerColor = ShapeStyling.COLOR_NEGATIVE;
            html = pinTriangle.replaceAll('style=""', 'style="--loc-marker-color: '+markerColor+'; stroke: #ffffff;"');

            iconSize = [24, 24];
            iconAnchor = [12, 22];
            popupAnchor = [0, -32];
        } else {
            //markerColor = ShapeStyling.COLOR_POSITIVE;
            html = pinCircle.replaceAll('style=""', 'style="--loc-marker-color: '+markerColor+'; stroke: #ffffff;"');
        }

        return L.divIcon({
            className: 'leaflet-loc-marker',
            html: html,
            iconSize: iconSize,
            iconAnchor: iconAnchor,
            popupAnchor: popupAnchor,
        });
    }


    const createMarker = (location, union) => {
        let filter = location.locations[0].filter;
        let dataVersion = location.locations[0].version;
        let marker = L.marker([location.lat, location.lng], {
            icon: createMarkerIcon(union, filter),
            propertyType: filter,
            dataVersion: dataVersion,
            zIndexOffset: sortOrder
        });
        marker.on('click', renderPopupRef.current.bind(null, marker, location.locations, dataVersion));

        L.Util.stamp(marker);

        return marker;
    }


    useEffect(() => {
        if (!mapContext.isInitialized) {return;}
        if (!mapContext.map.hasLayer(markerClusterPositive.current)) {
            mapContext.map.addLayer(markerClusterPositive.current);
        }
        if (!mapContext.map.hasLayer(markerClusterNegative.current)) {
            mapContext.map.addLayer(markerClusterNegative.current);
        }

        markerClusterPositive.current.clearLayers();
        markerClusterNegative.current.clearLayers();

        let joinedLocationsPositive = {}, joinedLocationsNegative = {};
        locationsByShape.positive.forEach((location) => {
            let coords = location.lat+','+location.lng+'|'+location.id;
            if (!joinedLocationsPositive[coords]) {
                joinedLocationsPositive[coords] = {
                    lat: location.lat,
                    lng: location.lng,
                    locations: []
                };
            }

            joinedLocationsPositive[coords].locations.push(location);
        });
        locationsByShape.negative.forEach((location) => {
            let coords = location.lat+','+location.lng;
            if (!joinedLocationsNegative[coords]) {
                joinedLocationsNegative[coords] = {
                    lat: location.lat,
                    lng: location.lng,
                    locations: []
                };
            }

            joinedLocationsNegative[coords].locations.push(location);
        });

        let markersPositiveNew = [], markersNegativeNew = [];
        Object.values(joinedLocationsPositive).forEach((info) => {
            let marker = createMarker(info, true);
            marker.bindPopup(info.locations.length+' locations');
            markerClusterPositive.current.addLayer(marker);
            markersPositiveNew.push(marker);
        });
        markersPositive.current = markersPositiveNew;
        if (!clusterified) {
            markerClusterPositive.current.disableClustering();
        } else {
            markerClusterPositive.current.enableClustering();
        }
        Object.values(joinedLocationsNegative).forEach((info) => {
            let marker = createMarker(info, false);
            marker.bindPopup(info.locations.length+' locations');
            markerClusterNegative.current.addLayer(marker);
            markersNegativeNew.push(marker);
        });
        markersNegative.current = markersNegativeNew;
        if (!clusterified) {
            markerClusterNegative.current.disableClustering();
        } else {
            markerClusterNegative.current.enableClustering();
        }

        return () => {
            if (mapContext.map.hasLayer(markerClusterPositive.current)) {
                mapContext.map.removeLayer(markerClusterPositive.current);
            }
            if (mapContext.map.hasLayer(markerClusterNegative.current)) {
                mapContext.map.removeLayer(markerClusterNegative.current);
            }
        }
    }, [mapContext.isInitialized, locationsByShape]);


    useEffect(() => {
        if (mapContext.map.hasLayer(markerClusterPositive.current)) {
            markerClusterPositive.current.options.iconCreateFunction = (cluster) => ClusterStyling.getPositiveClusterIcon(cluster, shapeColor);
            markerClusterPositive.current.refreshClusters();
        }
        if (mapContext.map.hasLayer(markerClusterNegative.current)) {
            markerClusterNegative.current.options.iconCreateFunction = (cluster) => ClusterStyling.getNegativeClusterIcon(cluster, shapeColor);
            markerClusterNegative.current.refreshClusters();
        }

        if (markersPositive.current.length) {
            markersPositive.current.forEach((marker) => {
                marker.setIcon(createMarkerIcon(true, marker.options.propertyType));
            });
        }
        if (markersNegative.current.length) {
            markersNegative.current.forEach((marker) => {
                marker.setIcon(createMarkerIcon(false, marker.options.propertyType));
            });
        }
    }, [shapeColor]);


    return null;
}