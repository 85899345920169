import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {useApi} from "../../../../providers/ApiProvider";
import ProjectsModel from "../../../../models/ProjectsModel";
import {useNotifications} from "../../../../providers/NotificationsProvider";


export default function MapInfoPanel({project, type, children, onValueChange}) {
    const {projectId} = useParams();
    const apiContext = useApi();
    const projectsModel = new ProjectsModel(apiContext.api);
    const notifications = useNotifications();

    const [loading, setLoading] = React.useState(false);
    const [editMode, setEditMode] = React.useState(false);
    const [value, setValue] = React.useState(project[type]);
    const [projectStatus, setProjectStatus] = useState(project.status ? project.status : '30_cancelled');


    useEffect(() => {
        if (project.status) {
            setProjectStatus(project.status);
        }
    }, [project.status]);


    useEffect(() => {
        setValue(project[type]);
    }, [project[type]]);


    const updateValue = (e) => {
        setValue(e.target.value);
    }


    const save = () => {
        setLoading(true);
        projectsModel.update(projectId, {[type]: value}).then(() => {
            notifications.notify('Project updated!', 'success');
        }).finally(() => {
            onValueChange(value);
            setEditMode(false);
            setLoading(false);
        });
    }


    return (<>
        {editMode ? (<>
            <input type="text" className="form-control map-info-control" value={value} onChange={updateValue} />
            <button className="icon-button d-flex" onClick={save}><i className={loading ? 'spinner-border spinner-border-sm' : 'ti ti-device-floppy fs-4'}></i></button>
        </>) : (<>
            {children}
            <button className={'icon-button'+(projectStatus !== '00_in_progress' ? ' d-none' : '')} onClick={() => {setEditMode(true)}}><i className="ti ti-pencil fs-4"></i></button>
        </>)}
    </>);
}