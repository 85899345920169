import React, {useEffect} from 'react';

import {useBreadcrumbs} from "../../providers/BreadcrumbsProvider";

import {useApi} from "../../providers/ApiProvider";
import {useTranslation as useMyTranslation} from "../../providers/TranslationProvider";
import imgLogo from "../../assets/img/intro.png";

export default function Home(props) {
    const {language} = useMyTranslation();
    const breadcrumbs = useBreadcrumbs();
    const apiContext = useApi();


    useEffect(() => {
        breadcrumbs.update({
            title: null
        });

        document.title = 'Welcome | Geolist';
    }, [language]);


    return (
        <div className="position-relative overflow-hidden min-vh-100 d-flex align-items-center justify-content-center">
            <div className="d-flex align-items-center justify-content-center w-100">
                <div className="row justify-content-center w-100">
                    <div className="col-lg-4">
                        <div className="text-center">
                            <img src={imgLogo} alt="" className="img-fluid"/>
                            <h1 className="fw-semibold mb-2 fs-9 mt-4">Geolist Data Tool</h1>
                            <div className="fw-semibold fs-5 mb-4">
                                Direct access to all mailing data in the United Kingdom.
                            </div>
                            <div className="fs-3">
                                Email <a href="mailto:team@geolist.co.uk">team@geolist.co.uk</a> to open an account.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}