import {useEffect, useRef, useState} from "react";
import CoordinatesModel from "../../../models/CoordinatesModel";

export default function MarkerPopup({marker, markerLocations, dataVersion, apiContext}) {
    const coordinatesModel = new CoordinatesModel(apiContext.api);
    const [businessLocationId, setBusinessLocationId] = useState('');
    const [businessType, setBusinessType] = useState('');
    const [buildingsCount, setBuildingsCount] = useState(0);

    const [info, setInfo] = useState(null);

    useEffect(() => {
        if (!markerLocations) {return;}

        //coordinatesModel.coordinatesInfo(marker.getLatLng().lat, marker.getLatLng().lng).then((data) => {
            const filter = markerLocations[0].filter ? markerLocations[0].filter : 1;
            let newBusinessType = 'Residential';
            if (filter === 2) {newBusinessType = 'Commercial';}
            if (filter > 2) {newBusinessType = 'Not yet built';}
            setBusinessType(newBusinessType);

            const newBuildingsCounts = markerLocations[0].buildings_count ? markerLocations[0].buildings_count : 0;
            setBuildingsCount(newBuildingsCounts);

            const newBuildingLocationId = markerLocations[0].building_location_id ? markerLocations[0].building_location_id : '';
            setBusinessLocationId(newBuildingLocationId);
        //});
    }, [markerLocations]);

    return (
        <>
            BLID: {businessLocationId}<br />
            Business Type: {businessType}<br />
            Buildings Count: {buildingsCount}
        </>
    );
}