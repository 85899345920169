import BaseCrudModel from "./BaseCrudModel";

/**
 * ProjectShares model
 *
 * @param {ApiHelper} api
 */
export default class ProjectSharesModel extends BaseCrudModel {
    baseUrl = '/project-shares';

    constructor(api) {
        super(api);
    }
}