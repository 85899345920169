import React, {useEffect} from 'react';

import {useApi} from "../../providers/ApiProvider";
import {useParams} from "react-router-dom";
import {useNotifications} from "../../providers/NotificationsProvider";
import ProjectsModel from "../../models/ProjectsModel";
import Map from "../map/general/Map";
import {useAuth} from "../../providers/AuthProvider";

export default function ProjectView(props) {
    const {user} = useAuth();
    const params = useParams();
    const apiContext = useApi();
    const projectsModel = new ProjectsModel(apiContext.api);
    const notifications = useNotifications();

    const [data, setData] = React.useState({});


    useEffect(() => {
        let viewParams = {
            pk: params.projectId
        };
        if (params.accessToken) {
            viewParams.ua = params.accessToken;
        }

        projectsModel.view(viewParams, uploadErrorHandler).then((response) => {
            if (response !== false) {
                if (!response.data || !response.data.union) {
                    let responseJSON = '{"union": {"type": "Feature", "geometry": {"type": "Polygon", "coordinates": []}, "properties": []}, "bounds": [], "shapes": []}';
                    response.data = JSON.parse(responseJSON);
                }

                if (viewParams.ua) {
                    response.universalAccessKey = viewParams.ua;
                } else {
                    response.readOnly = !userCanEdit(response);
                }

                setData(response);
                document.title = response.title+' | GeoList';
            }
        }).catch(uploadErrorHandler);
    }, []);


    const uploadErrorHandler = (response) => {
        if (response.error) {
            window.location.href = '/auth/login';
            //notifications.notify(response.error, 'danger');
        }
    }


    const userCanEdit = (project) => {
        if (project.user_id === user.id) {
            return true;
        }

        if (project.shares) {
            let share = project.shares.find((share) => share.invited_id === user.id);
            if (share && share.level === 'edit') {
                return true;
            }
        }

        return false;
    }


    return data.id ? (
        <Map project={data} />
    ) : '';
}
