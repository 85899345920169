import {LexicalComposer} from "@lexical/react/LexicalComposer";
import {RichTextPlugin} from "@lexical/react/LexicalRichTextPlugin";
import {ContentEditable} from "@lexical/react/LexicalContentEditable";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import {OnChangePlugin} from "@lexical/react/LexicalOnChangePlugin";
import {useEffect} from "react";
import {useLexicalComposerContext} from "@lexical/react/LexicalComposerContext";

function ReadOnlyPlugin() {
    const [editor] = useLexicalComposerContext();

    useEffect(() => {
        editor.setEditable(false); // Make the editor read-only
    }, [editor]);

    return null;
}

export default function ShapeNotesDisplay({initialJSON}) {
    const theme = {
        text: {
            strikethrough: 'text-decoration-line-through',
            underline: 'text-decoration-underline',
            underlineStrikethrough: 'text-decoration-line-through text-decoration-underline',
        }
    }


    const onError = (error) => {
        console.error(error);
    }


    const initialConfig = {
        namespace: 'MyEditor',
        theme,
        onError,
    };
    if (initialJSON) {
        initialConfig.editorState = initialJSON;
    }


    return (
        <LexicalComposer initialConfig={initialConfig} theme={theme}>
            <div className="editor-container">
                <div className="editor-inner">
                    <RichTextPlugin
                        contentEditable={<ContentEditable />}
                        ErrorBoundary={LexicalErrorBoundary}
                     placeholder={''}/>
                </div>
            </div>
            <ReadOnlyPlugin />
        </LexicalComposer>
    );
}